@use '@queries' as *;
@use '@content' as *;

.container {
  @include min-744-break {
    min-height: 475px;
    max-height: 475px;
  }

  @include min-1024-break {
    min-height: 356px;
    max-height: 356px;
  }

  @include min-1440-break {
    min-height: 473px;
    max-height: 473px;
  }
}
