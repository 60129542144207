@use '@fixed-size' as *;
@use '@typography' as *;
@use '@tabs' as *;
@use '@queries' as *;
@use '@content' as *;

.item-card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: max-content;

  .breadcrumbs {
    display: flex;
    align-items: center;
    width: max-content;
  }

  .content {
    display: grid;
    grid-template-columns: 270px minmax(330px, 100%);
    grid-column-gap: 24px;
    width: 100%;
    margin-bottom: 10px;

    @include min-1440-break {
      grid-template-columns: 386px minmax(561px, 768px);
    }

    .left {
      display: flex;
      flex-direction: column;
      gap: 14px;
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: 14px;
      max-width: 100%;

      @include min-1440-break {
        gap: 24px;
      }
      .title-info,
      .age,
      .bundles,
      .properties {
        display: flex;
        flex-direction: column;
        gap: 14px;
        max-height: max-content;

        .tags {
          display: flex;
          gap: 12px;

          .tag-info {
            padding: 5.5px 16px;
            border-radius: var(--cornerradius8);
            @include fixedSize(max-content, 32px);

            p {
              @include typography-base;
              @include weight-semi-bold;
            }

            @include min-1440-break {
              @include fixedSize(max-content, 36px);
            }
          }
        }

        .bundles-list-wrapper {
          @include fixedSize(100%, max-content);
          overflow-x: auto;
          overflow-y: hidden;
          cursor: grab;
          @include hideScrollbar;
        }

        .title {
          @include typography-m;
          @include weight-semi-bold;
          color: var(--text-primary);

          @include min-1440-break {
            @include typography-l;
          }
        }

        .bundles-list {
          display: flex;
          gap: 14px;
          max-height: max-content;
          flex-wrap: nowrap;
          width: 100%;
          > * {
            user-select: none;
          }
        }
      }

      .title-info {
        .title {
          @include typography-l;
          @include weight-semi-bold;
          color: var(--text-primary);

          @include min-1440-break {
            @include typography-2xl;
          }
        }
      }

      .properties {
      }

      .age {
        .tabs-container {
          display: flex;
          flex-wrap: wrap;
        }
      }

      .properties,
      .age,
      .descr {
        .title {
          @include typography-m;
          @include weight-semi-bold;

          @include min-1440-break {
            @include typography-l;
          }
        }

        .tabs-container {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          align-items: center;
          .tab-item {
            padding: 7.5px 16px;
            @include tabItemSizes('36', '36', '36', '36', '46', '46');

            span[id='tag-category'],
            svg {
              @include singleFixedSize(18px);
            }

            @include min-1440-break {
              padding: 11px 16px;
            }
          }
        }
      }

      .descr {
        display: none;
        @include min-1440-break {
          display: block;
        }
      }
    }
  }

  .descr-content {
    .title {
      @include typography-m;
      @include weight-semi-bold;

      @include min-1440-break {
        @include typography-l;
      }
    }
  }
}

.descr-content {
  @include min-1440-break {
    display: none;
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: flex-start;
  margin-top: 10px;

  @include min-1440-break {
    margin-top: 0;
  }

  .description-container {
    display: flex;
    align-items: center;
    gap: 2.5px;
  }
}

.text {
  color: #606060;
  @include typography-s;
  @include weight-medium;

  @include min-1440-break {
    @include typography-base;
  }
}

.skeleton {
  &--breadcrumbs {
    min-height: 24px;
    max-width: 320px;
  }

  &--item {
    border-radius: var(--cornerradius10);
    @include singleFixedSize(260px);

    @include min-1440-break {
      @include singleFixedSize(386px);
    }
  }

  &--btn {
    min-height: 42px;
    border-radius: var(--cornerradius10);
    @include min-1440-break {
      min-height: 46px;
    }
  }

  &--info {
    min-height: 73px;
    border-radius: var(--cornerradius10);
    @include min-1440-break {
      min-height: 86px;
    }
  }

  &--prop,
  &--descr {
    min-height: 74px;
    max-height: 118px;
    border-radius: var(--cornerradius10);
    @include min-1440-break {
      min-height: 87px;
    }
  }
}
