@use '@queries' as *;

.empty {
  align-self: center;
  padding: 6px 0;

  @include min-1440-break {
    padding: 22px 0;
  }
}
