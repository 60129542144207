@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@queries' as *;

.live-feed {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  align-items: flex-start;
  overflow-x: hidden;
  max-width: 100%;

  .top-slot {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 1;
    align-items: center;

    .label {
      span:has(svg),
      svg {
        color: #6941c6;
      }
    }
  }

  .bottom-slot {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 10.5px;
    max-width: 100%;
    overflow-x: auto;
    position: relative;

    mask: linear-gradient(90deg, #0000, #000 0% 92%, #0000);

    @include hideScrollbar;

    @include min-1440-break {
      gap: 14.75px;
    }
  }
}

.tabs-container {
  gap: 0;
  border-radius: var(--cornerradius8);
  background: var(--cst-live-feed-background-tabs, #f6f6f6);
  width: 173px;
  > button {
    padding: 8px 12px;
    p {
      @include typography-s;
      @include weight-medium;
    }
  }

  @include min-1440-break {
    border-radius: var(--cornerradius10);
    width: 242px;

    > button {
      padding: 11px 18px;
      p {
        @include typography-m;
      }
    }
  }
}

.live-feed-skeleton {
  border-radius: var(--cornerradius12);
  @include fixedSize(100px, 136px);

  @include min-1440-break {
    @include fixedSize(138px, 162px);
  }
}

.live-feed-tabs-skeleton {
  border-radius: var(--cornerradius8);
  @include fixedSize(117px, 34px);

  @include min-1440-break {
    @include fixedSize(164px, 46px);
  }
}
