@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.swiper-top-banner {
  overflow: hidden;
  cursor: pointer;
  position: relative;
  border-radius: var(--cornerradius16);
}

.swiper-bottom-banner {
  overflow: hidden;
  cursor: pointer;
  aspect-ratio: 984/178;
  @include fixedSize(100%, 178px);
  border-radius: var(--cornerradius16);

  @include min-1440-break {
    @include fixedSize(100%, 228px);
    aspect-ratio: 1360/228;
  }
}
