@use '@tabs' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include min-744-break {
    gap: 16px;
  }
  @include min-1024-break {
    gap: 18px;
  }
  @include min-1440-break {
    width: 278px;
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include min-1024-break {
      height: 18px;
    }
    @include min-1440-break {
      height: 24px;
    }
  }
  &-filters {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
}

.title {
  @include typography-s;
  @include min-428-break {
    @include typography-m;
  }
  @include min-1024-break {
    @include typography-s;
  }
  @include min-1440-break {
    @include typography-base;
  }
}

.mobile-container {
  gap: 6px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.chip {
  //@include tabItemSizes('36', '44', '44', '32', '46', '46');

  @include fixedSize(max-content, 36px);
  border-radius: var(--cornerradius10);
  gap: 6px;
  padding: 7px 12px;

  @include min-428-break {
    @include fixedSize(max-content, 44px);
    gap: 6px;
  }

  span[id='chip-close-btn-icon'] {
    svg {
      @include singleFixedSize(18px);
    }
  }
  p {
    @include typography-s;
    @include weight-semi-bold;

    @include min-428-break {
      @include typography-base;
    }
  }
}

.gap12 {
  gap: 12px;
}
