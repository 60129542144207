@use '@typography' as *;
@use '@queries' as *;

.content_header {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.input_container {
  width: 246px;

  @include min-1440-break {
    width: 328px;
  }
}

.sort_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;

  &-title {
    @include typography-base;

    @include min-1440-break {
      @include typography-m;
    }
  }
}
