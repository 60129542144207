@use '@fixed-size' as *;
@use '@queries' as *;

.body {
  gap: 8px;

  @include min-428-break {
    gap: 12px;
  }

  @include min-428-break {
    gap: 10px;
  }

  .description {
    @include min-1440-break {
      margin-top: 20px;
    }
  }
}

.container {
  div[id='modal-content-container'] {
    padding-top: 28px;
  }

  .title {
    @include min-1440-break {
      margin-top: -1px;
    }
  }
  @include min-744-break {
    min-height: 711px;
  }

  @include min-1024-break {
    min-height: 614px;
  }

  @include min-1440-break {
    min-height: 739px;
  }
}
