.chip {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  height: 46px;
  width: max-content;
  min-width: max-content;
  transition: all 0.12s ease-in-out;
}

.variant {
  &--transport {
    background: var(--cst-chips-transport-background-default);
    box-shadow: inset 0px 0px 0px 2px var(--cst-chips-transport-outline-default);

    p {
      color: var(--cst-chips-transport-text-default);
    }

    color: var(--cst-chips-transport-text-default);

    &:hover {
      box-shadow: inset 0px 0px 0px 2px var(--cst-chips-transport-outline-hover);
      color: var(--cst-chips-transport-trailingiconbackground-hover);
      p {
        color: var(--cst-chips-transport-text-hover);
      }
    }

    &:active {
      box-shadow: inset 0px 0px 0px 2px
        var(--cst-chips-transport-outline-pressed);

      p {
        color: var(--cst-chips-transport-text-pressed);
      }
    }

    &:focus-within {
      box-shadow: inset 0px 0px 0px 2px
        var(--cst-chips-transport-outline-focused);
      color: var(--cst-chips-transport-trailingicon-pressed);
      p {
        color: var(--cst-chips-transport-text-focused);
      }
    }

    &.disabled {
      background: var(--cst-chips-transport-background-disabled);
      color: var(--cst-chips-transport-trailingiconbackground-disabled);
      p {
        color: var(--cst-chips-transport-text-disabled);
      }
    }
  }

  &--egg {
    background: var(--cst-chips-egg-background-default);
    box-shadow: inset 0px 0px 0px 2px var(--cst-chips-egg-outline-default);
    color: var(--cst-chips-egg-trailingiconbackground-default);

    p {
      color: var(--cst-chips-egg-text-default);
    }

    &:hover {
      box-shadow: inset 0px 0px 0px 2px var(--cst-chips-egg-outline-hover);
      color: var(--cst-chips-egg-trailingiconbackground-hover);
      p {
        color: var(--cst-chips-egg-text-hover);
      }
    }

    &:active {
      box-shadow: inset 0px 0px 0px 2px var(--cst-chips-egg-outline-pressed);
      color: var(--cst-chips-egg-trailingiconbackground-pressed);
      p {
        color: var(--cst-chips-egg-text-pressed);
      }
    }

    &:focus-within {
      box-shadow: inset 0px 0px 0px 2px var(--cst-chips-egg-outline-focused);
      color: var(--cst-chips-egg-trailingiconbackground-focused);
      p {
        color: var(--cst-chips-egg-text-focused);
      }
    }

    &.disabled {
      background: var(--cst-chips-egg-background-disabled);
      color: var(--cst-chips-egg-trailingiconbackground-disabled);
      p {
        color: var(--cst-chips-egg-text-disabled);
      }
    }
  }

  &--potion {
    background: var(--cst-chips-potion-background-default);
    box-shadow: inset 0px 0px 0px 2px var(--cst-chips-potion-outline-default);
    color: var(--cst-chips-potion-trailingiconbackground-default);
    p {
      color: var(--cst-chips-potion-text-default);
    }

    &:active {
      box-shadow: inset 0px 0px 0px 2px var(--cst-chips-potion-outline-pressed);
      color: var(--cst-chips-potion-trailingiconbackground-pressed);
      p {
        color: var(--cst-chips-potion-text-pressed);
      }
    }

    &:hover {
      box-shadow: inset 0px 0px 0px 2px var(--cst-chips-potion-outline-hover);
      color: var(--cst-chips-potion-trailingiconbackground-hover);
      p {
        color: var(--cst-chips-potion-text-hover);
      }
    }

    &:focus-within {
      box-shadow: inset 0px 0px 0px 2px var(--cst-chips-potion-outline-focused);
      color: var(--cst-chips-potion-trailingiconbackground-focused);
      p {
        color: var(--cst-chips-potion-text-focused);
      }
    }

    &.disabled {
      background: var(--cst-chips-potion-background-disabled);
      color: var(--cst-chips-potion-trailingiconbackground-disabled);
      p {
        color: var(--cst-chips-potion-text-disabled);
      }
    }
  }

  &--pet {
    background: var(--cst-chips-cat-background-default);
    box-shadow: inset 0px 0px 0px 2px var(--cst-chips-cat-outline-default);
    color: var(--cst-chips-cat-trailingiconbackground-default);
    p {
      color: var(--cst-chips-cat-text-default);
    }

    &:hover {
      box-shadow: inset 0px 0px 0px 2px var(--cst-chips-cat-outline-hover);
      color: var(--cst-chips-cat-trailingiconbackground-hover);
      p {
        color: var(--cst-chips-cat-text-hover);
      }
    }

    &:focus-within {
      color: var(--cst-chips-cat-trailingiconbackground-focused);
      box-shadow: inset 0px 0px 0px 2px var(--cst-chips-cat-outline-focused);
      p {
        color: var(--cst-chips-cat-text-focused);
      }
    }

    &:active {
      color: var(--cst-chips-cat-trailingiconbackground-pressed);
      box-shadow: inset 0px 0px 0px 2px var(--cst-chips-cat-outline-pressed);
      p {
        color: var(--cst-chips-cat-text-pressed);
      }
    }

    &.disabled {
      background: var(--cst-chips-cat-background-disabled);
      color: var(--cst-chips-cat-trailingiconbackground-disabled);
      p {
        color: var(--cst-chips-cat-text-disabled);
      }
    }
  }

  &--primary {
    background: var(--cst-chips-simple-background-default);
    color: var(--cst-chips-simple-trailingicon-default);
    box-shadow: inset 0px 0px 0px 2px var(--cst-chips-simple-outline-default);
    p {
      color: var(--cst-chips-simple-text-default);
    }

    &:hover {
      color: var(--cst-chips-simple-trailingiconbackground-hover);
      box-shadow: inset 0px 0px 0px 2px var(--cst-chips-simple-outline-hover);
      p {
        color: var(--cst-chips-simple-text-hover);
      }
    }

    &.chip--selected,
    &:active {
      color: var(--cst-chips-simple-trailingiconbackground-pressed);
      box-shadow: inset 0px 0px 0px 2px var(--cst-chips-simple-outline-pressed);
      p {
        color: var(--cst-chips-simple-text-pressed);
      }
    }

    &:focus {
      color: var(--cst-chips-simple-trailingicon-focused);
      box-shadow: inset 0px 0px 0px 2px var(--cst-chips-simple-outline-focused);
      p {
        color: var(--cst-chips-simple-text-focused);
      }
    }

    &.disabled {
      color: var(--cst-chips-simple-trailingiconbackground-disabled);
      background: var(--cst-chips-simple-background-disabled);
      p {
        color: var(--cst-chips-simple-text-disabled);
      }
    }
  }

  &--quaternary {
    background: var(--cst-tabitem-quaternary-background-default);
    color: var(--cst-tabitem-quaternary-text-default);
    box-shadow: inset 0px 0px 0px 2px
      var(--cst-tabitem-quaternary-outline-default);
    p {
      color: var(--cst-tabitem-quaternary-text-default);
    }

    &.chip--selected,
    &:active {
      background: var(--cst-tabitem-quaternary-background-pressed);
      color: var(--cst-tabitem-quaternary-text-pressed);
      box-shadow: inset 0px 0px 0px 2px
        var(--cst-tabitem-quaternary-outline-pressed);
      p {
        color: var(--cst-tabitem-quaternary-text-pressed);
      }
    }

    &:focus {
      background: var(--cst-tabitem-quaternary-background-focused);
      color: var(--cst-tabitem-quaternary-text-focused);
      box-shadow: inset 0px 0px 0px 2px
        var(--cst-tabitem-quaternary-outline-focused);
      p {
        color: var(--cst-tabitem-quaternary-text-focused);
      }
    }

    &.disabled {
      background: var(--cst-tabitem-quaternary-background-disabled);
      color: var(--cst-tabitem-quaternary-text-disabled);
      box-shadow: inset 0px 0px 0px 2px
        var(--cst-tabitem-quaternary-outline-disabled);
      p {
        color: var(--cst-tabitem-quaternary-text-disabled);
      }
    }
  }
}

.disabled {
  box-shadow: none;
  pointer-events: none;
}

.close-btn {
  all: unset;
  box-sizing: border-box;
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: currentColor;
    }
  }
}
