@use '@/app/queries' as *;
@use '@typography' as *;
@use '@fixed-size' as *;

.container {
  width: 100%;
  cursor: text;
  display: flex;
  flex-direction: column;
  gap: 5px;
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
}

.content_container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px;
  border: 2px solid var(--cst-inputauth-outline-default);
  border-radius: var(--cornerradius8);
  gap: 10px;
  margin-bottom: 0;
  transition: all 0.2s ease;
  &.filled-disabled {
    opacity: 1;
    background-color: #f6f6f6;
  }

  @include min-1440-break {
    padding: 12px 14px;
    gap: 12px;
  }

  span {
    @include singleFixedSize(18px);
    @include min-428-break {
      @include singleFixedSize(24px);
    }
    @include min-1024-break {
      @include singleFixedSize(18px);
    }
    @include min-1440-break {
      @include singleFixedSize(24px);
    }
  }

  &.checked {
    background-color: #f6f6f6;
    border: 2px solid #efefef;
    pointer-events: none;
  }
  &:hover {
    border: 2px solid #fdfdfd;
    box-shadow: 0 0 0 4px #6941c633;
  }
  &:focus-within {
    border: 2px solid var(--cst-inputauth-outline-pressed);
    box-shadow: 0 0 0 4px #6941c633;
  }

  &.error {
    border: 2px solid #eb3d3d;
    box-shadow: 0px 0px 0px 4px #eb3d3d33;
  }
  &.m {
    height: 42px;
    @include min-428-break {
      height: 52px;
    }
    @include min-1024-break {
      height: 42px;
    }
    @include min-1440-break {
      height: 52px;
    }
  }
  height: 42px;
  @include min-428-break {
    height: 48px;
  }
  @include min-1024-break {
    height: 42px;
  }
  @include min-1440-break {
    height: 48px;
  }
}

.input_wrapper {
  flex: 1;
  position: relative;
}

.input_field {
  padding: 0;
  outline: none;
  border: none;
  height: 95%;
  margin-top: 17px;
  width: 100%;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  &.filled-disabled {
    opacity: 1;
    background-color: #f6f6f6;
  }
  @include min-428-break {
    font-size: 14px;
    line-height: 21px;
  }
  @include min-1024-break {
    font-size: 12px;
    line-height: 18px;
  }
  @include min-1440-break {
    font-size: 14px;
    line-height: 21px;
  }
  &.checked {
    background-color: #f6f6f6;
  }
}

.icon {
  cursor: pointer;
}

.text {
  letter-spacing: 0;
  margin: 0 2px;
  @include typography-xs;
  @include min-428-break {
    @include typography-s;
  }
  @include min-1024-break {
    @include typography-xs;
  }
  @include min-1440-break {
    @include typography-s;
  }
}

.error_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 20px;
}

.error {
  color: var(--cst-inputauth-subtitle-error);
}

.label {
  position: absolute;
  top: 7px;
  left: 0;
  transition: all 300ms;
  font-family: Inter, sans-serif;
  color: var(--cst-inputauth-title-default);
  @include typography-s;
  @include min-428-break {
    @include typography-base;
  }
  @include min-1024-break {
    @include typography-s;
  }
  @include min-1440-break {
    @include typography-base;
  }

  cursor: text;
}

.input_field:focus ~ .label,
.input_field:not(:placeholder-shown).input_field:not(:focus) ~ .label {
  top: 2px;
  @include typography-xs;
  @include min-428-break {
    top: 0;
    @include typography-s;
  }

  @include min-1024-break {
    top: 0;
    @include typography-xs;
  }

  @include min-1440-break {
    top: 0;
    @include typography-s;
  }
}
