@use '@queries' as *;
@use '@content' as *;

.timer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.container {
  @include min-744-break {
    min-height: 673px;
  }

  @include min-1024-break {
    min-height: 582px;
  }

  @include min-1440-break {
    min-height: 709px;
  }
}
