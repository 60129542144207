@use '@/app/queries' as *;

.switch {
  position: relative;
  display: inline-block;
  min-width: 34px;
  max-width: 34px;
  height: 19px;
  @include min-428-break {
    min-width: 42px;
    max-width: 42px;
    height: 25px;
  }
  @include min-1024-break {
    min-width: 34px;
    max-width: 34px;
    height: 19px;
  }
  @include min-1440-break {
    min-width: 46px;
    max-width: 46px;
    height: 24px;
  }
  & .input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.checkbox:focus-visible + .slider {
  border: 2px solid var(--cst-switch-outlineon-focused);
}

.slider {
  position: absolute;
  display: block;
  cursor: pointer;
  border-radius: var(--cornerradius12);
  background-color: var(--cst-switch-backgroundon-default);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 2px solid var(--cst-switch-outlineon-default);

  .toggle {
    height: 15px;
    width: 15px;
    left: 2px;
    bottom: 2px;
    transition: 0.4s;
    border-radius: 50px;
    @include min-428-break {
      height: 20px;
      width: 20px;
    }

    @include min-1024-break {
      height: 15px;
      width: 15px;
    }

    @include min-1440-break {
      height: 20px;
      width: 20px;
    }
  }
  & svg {
    transition: all 0.2s ease;
  }
  & svg > path:nth-child(2) {
    fill: var(--cst-switch-iconon-default);
  }
  & svg > path:nth-child(1) {
    fill: var(--cst-switch-handleon-hover);
    opacity: 1;
  }

  &:hover {
    & svg > path:nth-child(2) {
      fill: var(--global-white);
    }
    & svg > path:nth-child(1) {
      fill: var(--cst-switch-handleoff-hover);
      opacity: 1;
    }
  }
  &-disabled {
    & svg > path:nth-child(2) {
      fill: var(--cst-switch-iconoff-disabled);
    }

    & svg > path:nth-child(1) {
      fill: var(--cst-switch-handleoff-disabled);
      opacity: 1;
    }

    &:hover {
      & svg > path:nth-child(2) {
        fill: var(--global-white);
      }

      & svg > path:nth-child(1) {
        fill: var(--cst-switch-handleoff-pressed);
        opacity: 1;
      }
    }
  }
}

.checkbox:checked + .slider > .toggle {
  transform: translateX(14px);
  @include min-1024-break {
    transform: translateX(15px);
  }
  @include min-1440-break {
    transform: translateX(20px);
  }
}
