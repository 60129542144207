@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0 0 0 2px var(--cst-cards-item-offer-outline);
  padding: 16px;
  border-radius: var(--cornerradius16);

  @include fixedSize(100%, 410px);
  min-height: 410px;
  overflow: hidden;

  @include min-1440-break {
    padding: 20px;
    @include fixedSize(100%, 626px);
  }

  @include min-1920-break {
    @include fixedSize(100%, 571px);
  }
}

.icon {
  @include singleFixedSize(20px);

  @include min-1440-break {
    @include singleFixedSize(26px);
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &-title-text {
    @include typography-base;
    @include weight-semi-bold;

    @include min-1440-break {
      @include typography-m;
    }
  }

  &-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  &-amount-container {
  }
  &-text {
    color: rgba(33, 33, 33, 1);
  }
}

.scroll-container {
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  flex-grow: 1;
  overflow: hidden;
  overflow-y: scroll;

  @include min-1440-break {
    gap: 18px;
  }
}

.not-found {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: 14px;

  @include min-1440-break {
    gap: 18px;
  }
}

.not-found-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .title {
    @include typography-base;
    @include weight-semi-bold;
    color: var(--cst-side-bar-shop-text);

    @include min-1440-break {
      @include typography-m;
    }
  }

  .subtitle {
    text-align: center;
    @include typography-xs;
    @include weight-medium;

    color: var(--cst-side-bar-shop-descriprion);

    @include min-1440-break {
      @include typography-s;
    }
  }
}
