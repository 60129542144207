@use '@queries' as *;

.favorite {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 18px;

  @include min-1440-break {
    gap: 32px;
  }
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 16px;

  @include min-1440-break {
    gap: 18px;
  }
}
