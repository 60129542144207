@use '@queries' as *;
.container {
  position: relative;
  display: flex;
  min-width: 142px;
  min-height: 42px;
  background: var(--cst-plates-background);
  height: inherit;
  @include min-1440-break {
    min-height: 52px;
  }
}

.width {
  &--full-w {
    width: 100%;
  }
  &--content {
    width: max-content;
  }
}

.variant {
  &--6 {
    border-radius: var(--cornerradius6);
  }
  &--8 {
    border-radius: var(--cornerradius8);
  }
  &--10 {
    border-radius: var(--cornerradius10);
  }
  &--12 {
    border-radius: var(--cornerradius12);
  }
  &--16 {
    border-radius: var(--cornerradius16);
  }
}
