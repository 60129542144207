@use '@button' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.action-btn {
  @include buttonSizes('38', '46', '52', '42', '54', '54');

  @include fixedSize(100%, 38px);

  @include min-428-break {
    @include fixedSize(100%, 46px);
  }

  @include min-744-break {
    @include fixedSize(100%, 52px);
  }

  @include min-1024-break {
    @include fixedSize(100%, 42px);
  }

  @include min-1440-break {
    @include fixedSize(100%, 54px);
  }

  span[id='btn-icon'],
  svg {
    @include singleFixedSize(18px);

    @include min-1440-break {
      @include singleFixedSize(22px);
    }
  }
}
