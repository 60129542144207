@use '@/shared/ui/button/adaptive' as *;
@use '@fixed-size' as *;

.action-btn {
  @include buttonSizes('38', '38', '38', '38', '38', '38');

  @include fixedSize(100%, 38px);

  span[id='btn-icon'],
  svg {
    @include singleFixedSize(18px);
  }
}
