@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;
@use '@content' as *;

.container {
  padding: 18px 0 60px 0px;
  display: flex;
  height: 100%;
  flex: 1;
  @include min-1440-break {
    padding: 24px 0 60px 0px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @include min-1440-break {
    gap: 34px;
  }
}

.promo-images {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  overflow: hidden;

  &--middle-1,
  &--middle-2 {
    .promo-plate {
      position: relative;
      cursor: pointer;
      @include fixedSize(317.2px, 178px);

      @include min-1440-break {
        @include fixedSize(442.7px, 228px);
      }
      @include min-1920-break {
        @include fixedSize(528.7px, 228px);
      }
    }
  }

  &--middle-2 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &--bottom {
  }
}

.swiper-top-wrapper {
  border-radius: var(--cornerradius16);
  @include fixedSize(373px, 222px);
  @include min-1440-break {
    @include fixedSize(595px, 322px);
  }
}

.giveaway-top-banner {
  position: relative;
  border-radius: var(--cornerradius16);
  @include fixedSize(595px, 222px);
  @include min-1440-break {
    @include fixedSize(749px, 322px);
  }
  @include min-1920-break {
    @include fixedSize(1007px, 322px);
  }
}

.scroll-anchor {
  scroll-margin-top: 300px;
  @include min-1440-break {
    scroll-margin-top: 200px;
  }
}

.mobile-page {
  &__container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    @include containerPaddings;

    .wrapper {
      padding-top: 150px;

      @include min-428-break {
        padding-top: 180px;
      }
      @include mainContainerStyles;
    }
  }
}
