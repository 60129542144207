@use '@queries' as *;
@use '@content' as *;

.players-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;

  @include hideScrollbar;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 246px;

  @include min-428-break {
    gap: 10px;
    max-height: 465px;
  }

  @include min-744-break {
    max-height: 400px;
  }
}

.container {
  @include min-744-break {
    min-height: 673px;
  }

  @include min-1024-break {
    min-height: 446px;
  }

  @include min-1440-break {
    min-height: 548px;
  }
}
