@use '@fixed-size' as *;
@use '@queries' as *;

.icon {
  @include singleFixedSize(18px);

  @include min-428-break {
    @include singleFixedSize(20px);
  }

  @include min-1024-break {
    @include singleFixedSize(18px);
  }

  @include min-1440-break {
    @include singleFixedSize(20px);
  }
}

.container {
  @include min-744-break {
    min-height: 673px;
  }

  @include min-1024-break {
    min-height: 482px;
  }

  @include min-1440-break {
    min-height: 619px;
  }
}
