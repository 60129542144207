@use '@typography' as *;
@use '@queries' as *;
@use '@fixed-size' as *;

.additional-info {
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  min-height: 46px;
  justify-content: space-between;
  padding: 12px;
  @include min-1440-break {
    padding: 14px;
  }
}

.align {
  &-center {
    align-items: center;
  }

  &-start {
    align-items: flex-start;
  }

  &-end {
    align-items: flex-end;
  }
}

.width {
  &-content {
    width: max-content;
    min-width: 196px;
  }

  &-full-w {
    width: 100%;
  }
}

.variant {
  &-success {
    p {
      color: var(--cst-additional-info-title-green);
    }
    background: var(--cst-additional-info-background-green);
    box-shadow: inset 0px 0px 0px 2px var(--cst-additional-info-outline-green);
  }

  &-info {
    p {
      color: var(--cst-additional-info-title-blue);
    }
    background: var(--cst-additional-info-background-blue);
    box-shadow: inset 0px 0px 0px 2px var(--cst-additional-info-outline-blue);
  }

  &-warn {
    p {
      color: var(--cst-additional-info-title-orange);
    }

    background: var(--cst-additional-info-background-orange);
    box-shadow: inset 0px 0px 0px 2px var(--cst-additional-info-outline-orange);
  }

  &-danger {
    p {
      color: var(--cst-additional-info-title-red);
    }

    background: var(--cst-additional-info-background-red);
    box-shadow: inset 0px 0px 0px 2px var(--cst-additional-info-outline-red);
  }
}

.additional-info-icon {
  @include singleFixedSize(18px);

  @include min-1440-break {
    @include singleFixedSize(24px);
  }

  &--success {
    margin-left: 10px;
    color: var(--global-green600);
  }
  &--info {
    margin-left: 10px;
    color: var(--global-blue600);
  }

  &--warn {
    margin-left: 10px;
    color: var(--global-orange600);
  }

  &--danger {
    margin-left: 10px;
    color: var(--global-red600);
  }
}

.info {
  @include typography-s;
  @include weight-medium;

  @include min-1440-break {
    @include typography-base;
  }
}
