@use '@queries' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@tabs' as *;

@mixin TabItem {
  width: 100%;
  justify-content: flex-start;
  @include tabItemSizes('34', '42', '42', '42', '46', '46');
  padding: 10.5px 12px;
  @include min-1440-break {
    padding: 12.5px 12px;
  }
}

.left-container {
  min-width: 202px;
  width: 202px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include min-1440-break {
    min-width: 276px;
    width: 276px;
  }

  &__top-slot {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      @include min-1440-break {
        gap: 10px;
      }
    }
    &-balance-container {
      padding: 12px;
      @include fixedSize(100%, 66px);
      @include min-1440-break {
        padding: 14px;
        @include fixedSize(100%, 73px);
      }
    }
    .title {
      display: flex;
      flex-direction: row;
      gap: 8px;
      p {
        color: #303030;
      }
      svg {
        @include singleFixedSize(18px);
      }
    }
    .subtitle {
      color: #8f8f8f;
      white-space: nowrap;
    }
  }
  &__bottom-slot {
    display: flex;
    flex-direction: column;
    gap: 8px;
    @include min-1440-break {
      gap: 10px;
    }
    .log-out {
      @include TabItem;
    }
  }
}

.tabs {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  button {
    @include TabItem;
    box-shadow: none !important;
  }
}

.drop_list {
  width: 141px;
  left: 0;
  box-shadow: var(--elevation-1);
  background-color: var(--global-white);
  border-radius: var(--cornerradius10);
  outline: 2px solid #efefef;
  outline-offset: -2px;
  padding: 10px;
  margin-top: 0;
  @include min-1440-break {
    padding: 12px;
    width: 212px;
    left: 0;
    margin-top: 0;
  }
  @include min-1920-break {
    left: 0;
    margin-top: 0;
  }
}

.tooltip-container {
  position: absolute;
  height: max-content;
  width: max-content;
  bottom: -10px;
  left: 0;

  > div {
    margin-top: 0;
    left: 0;
  }
}
