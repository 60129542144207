@use '@typography' as *;
@use '@queries' as *;

.filter_item_container {
  &-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    @include typography-s;
    @include min-1440-break {
      @include typography-base;
    }
    color: var(--cst-filter-item-text-default);
    &:hover {
      color: var(--cst-filter-item-text-hover);
    }
  }
  &-active {
    color: var(--cst-filter-item-text-active);
  }
}
