@use '@queries' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@content' as *;

.list-cards {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  align-items: flex-start;

  @include min-1440-break {
    gap: 18px;
  }

  .top-slot {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    min-height: 40px;

    @include min-1440-break {
      min-height: 46px;
    }
  }

  .bottom-slot {
    display: grid;
    width: 100%;
    column-gap: 28.5px;
    row-gap: 18px;
    overflow-y: visible;
    max-height: 494px;

    @include hideScrollbar;

    @include min-1440-break {
      column-gap: 5px;
      row-gap: 24px;
      min-height: 630px;
    }

    @include min-1920-break {
      column-gap: 14px;
      row-gap: 24px;
    }

    &--primary {
      //grid-template-columns: repeat(auto-fit, 140px);

      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));

      @include min-1440-break {
        //grid-template-columns: repeat(auto-fit, 190px);
        grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
      }
    }

    &--secondary {
      //grid-template-columns: repeat(auto-fit, 140px);
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      column-gap: 5px;
      justify-content: space-between;

      @include min-1440-break {
        //grid-template-columns: repeat(auto-fit, 183px);
        grid-template-columns: repeat(auto-fill, minmax(183px, 1fr));
        column-gap: 14px;
        justify-content: flex-start;
      }
    }

    &--tertiary {
      //grid-template-columns: repeat(auto-fit, 188px);
      grid-template-columns: repeat(auto-fill, minmax(188px, 1fr));
      gap: 4px;

      @include min-1440-break {
        //grid-template-columns: repeat(auto-fit, 252px);
        grid-template-columns: repeat(auto-fill, minmax(252px, 1fr));
      }
    }
  }
}

.list-card-skeleton {
  border-radius: var(--cornerradius12);
  &.variant {
    &--primary {
      @include fixedSize(140px, 226px);
      @include min-1440-break {
        @include fixedSize(190px, 295px);
      }
    }

    &--secondary {
      @include fixedSize(140px, 226px);

      @include min-1440-break {
        @include fixedSize(183px, 283px);
      }
    }

    &--tertiary {
      @include fixedSize(188px, 307px);

      @include min-1440-break {
        @include fixedSize(252px, 425px);
      }

      &--quaternary {
        @include fixedSize(144px, 238px);
        @include min-1920-break {
          @include fixedSize(163px, 'content');
        }
      }
    }
  }
}

.list {
  min-height: 500px;

  @include hideScrollbar;

  @include min-1440-break {
    min-height: 650px;
  }
}

.virtualized-grid-components {
  &--list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    @include hideScrollbar;

    @include min-1440-break {
      gap: 0px;
    }

    &:not(:has(&--item:nth-child(6n))) {
      justify-content: flex-start;
    }
    &:not(:has(&--skeleton:nth-child(6n))) {
      justify-content: flex-start;
    }
  }

  &--item,
  &--skeleton {
    width: 15.66%;
    display: flex;
    margin-bottom: 32px;
    flex: none;
    justify-content: center;
    box-sizing: border-box;

    @include min-1440-break {
      width: 13.98%;
    }

    @include min-1920-break {
      width: 12.38%;
    }
  }

  &--skeleton {
    margin-bottom: 8px;
  }

  &--footer {
    padding: 2rem;
    display: flex;
    justify-content: center;
  }
}
