@use '../../../../app/queries' as *;
@use '../../../../shared/styles/fixed' as *;
@use '../../../../shared/ui/typography/sizes' as *;
@use '../../../../shared/ui/button/adaptive' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 14px;

  @include fixedSize(226px, max-content);

  @include min-428-break {
    @include fixedSize(282px, max-content);
  }
  @include min-1024-break {
    @include fixedSize(224px, max-content);
  }
  @include min-1440-break {
    @include fixedSize(288px, max-content);
    gap: 18px;
  }
}

.sidebar-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  .title,
  .subtitle {
    text-align: center;
  }
  .title {
    color: var(--cst-sidebar-password-text);
    @include typography-m;
    @include weight-semi-bold;

    @include min-428-break {
      @include typography-xl;
    }
    @include min-1024-break {
      @include typography-base;
    }
    @include min-1440-break {
      @include typography-m;
    }
  }

  .subtitle {
    color: var(--cst-sidebar-password-description);
    @include typography-s;
    @include weight-medium;

    @include min-428-break {
      @include typography-m;
    }
    @include min-1024-break {
      letter-spacing: 0.5px;
      @include typography-xs;
    }
    @include min-1440-break {
      @include typography-s;
    }
  }
}

.sidebar-btn {
  @include buttonSizes('34', '44', '44', '32', '42', '42');

  span[id='sidebar-fav-icon'],
  svg {
    @include singleFixedSize(16px);
    @include min-428-break {
      @include singleFixedSize(18px);
    }
    @include min-1024-break {
      @include singleFixedSize(16px);
    }
    @include min-1440-break {
      @include singleFixedSize(18px);
    }
  }
}
