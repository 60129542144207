@use '@button' as *;
@use '@fixed-size' as *;
@use '@queries' as *;
@use '@typography' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: var(--cornerradius16);
  box-shadow: 0 0 0 2px var(--cst-price-card-outline-add);
  padding: 16px;
  @include fixedSize(100%, 228px); //391

  @include min-1440-break {
    @include fixedSize(100%, 276px); //443
    padding: 20px;
    gap: 20px;
  }

  .top {
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 12px;

    @include min-1440-break {
      gap: 14px;
    }
  }
}

.buttons_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.top_button_container {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  gap: 5px;
}

.stepper_container {
  width: 100%;
}

.plate {
  @include fixedSize(100%, 148px);
}

.quantity-container {
  gap: 4px;
  button,
  input {
    @include typography-m;
    border-radius: var(--cornerradius12);
    @include singleFixedSize(42px);
    span {
      @include singleFixedSize(18px);
    }
  }
  @include min-1440-break {
    button,
    input {
      @include singleFixedSize(54px);
      span {
        @include singleFixedSize(22px);
      }
    }
  }
}
